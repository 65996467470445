import React from 'react';
import {Block} from 'react-bulma-components';
import Loader from './Loader';

const LoadingIndicator: React.FC = () => (
  <Block>
    <Loader style={{margin: 'auto'}} />
  </Block>
);

export default React.memo(LoadingIndicator);
