import {SearchPersonsParams, SearchPersonsResponse} from '../../api-models/person';
import httpService from '../http/http.service';
import {toCommonSearchParams} from '../../utils/search-requests.utils';

const personService = {
  async searchPersons(page: number, pageSize: number, query?: string): Promise<SearchPersonsResponse> {
    const params: SearchPersonsParams = toCommonSearchParams(page, pageSize, query);

    return httpService.get<SearchPersonsResponse>('/persons', params)
      .catch((err) => {
        if (err?.status === 401) {
          return Promise.resolve({
            items: [],
            total: 0,
          });
        }
        throw (err);
      });
  }
};

export default personService;
