import React from 'react';

const AccessDeniedPage: React.FC = () => {
  return (
    <div>
      401 - Access Denied
    </div>
  );
};

export default AccessDeniedPage;
