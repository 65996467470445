import React, {KeyboardEvent, useState} from 'react';
import {Button, Form} from 'react-bulma-components';

interface Props {
    readonly placeholder?: string;
    readonly onSearch: (query?: string) => void;
}

const GridSearchPanel: React.FC<Props> = ({placeholder, onSearch}) => {
  const [query, setQuery] = useState('');

  const canReset = query == null || query.length === 0;

  const onReset = (): void => {
    setQuery('');
    onSearch('');
  };

  const doSearching = () => onSearch(query);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      doSearching();
    }
  };

  return (
    <Form.Field kind='group'>
      <Form.Control fullwidth>
        <Form.Input
          placeholder={placeholder}
          value={query}
          onChange={({target: {value}}) => setQuery(value)}
          onKeyDown={onKeyDown}
        />
      </Form.Control>

      <Form.Control>
        <Button
          disabled={canReset}
          onClick={onReset}
        >
          Reset
        </Button>
      </Form.Control>

      <Form.Control>
        <Button onClick={doSearching} color="link">
          Search
        </Button>
      </Form.Control>
    </Form.Field>
  );
};

export default GridSearchPanel;
