import React, {useEffect, useState} from 'react';
import {Box, Modal} from 'react-bulma-components';
import dltTransactionLogService from '../../services/dlt-transaction/dlt-transaction-log.service';
import {DltTransactionLogFullDetailsResponse} from '../../api-models/dlt-transaction';
import GridLoadingIndicator from '../common/GridLoadingIndicator';
import DltTransactionDetails from './DltTransactionDetails';

interface Props {
    readonly transactionId: string;
    readonly onClose: () => void;
}

const DltTransactionDetailsModal: React.FC<Props> = ({transactionId, onClose}) => {
  const [data, setData] = useState<DltTransactionLogFullDetailsResponse>();

  useEffect(() => {
    (async () => {
      const details = await dltTransactionLogService.getTransactionDetails(transactionId);

      setData(details);
    })();
  }, []);

  return (
    <Modal
      show
      closeOnEsc
      closeOnBlur
      onClose={onClose}
    >
      <Modal.Content style={{width: 900}}>
        <Box>
          {data == null ? <GridLoadingIndicator /> : <DltTransactionDetails transaction={data.transaction} outcome={data.dltOutcome} />}
        </Box>
      </Modal.Content>
    </Modal>
  );
};

export default DltTransactionDetailsModal;
