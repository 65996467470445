import React from 'react';
import {Route, Routes} from 'react-router';
import AccountsPage from './pages/AccountsPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import AccessDeniedPage from './pages/AccessDeniedPage';
import ProtectedRoute from './ProtectedRoute';
import {useAuthContext} from './contexts/auth.context';
import DltTransactionsPage from './pages/DltTransactionsPage';

const AppRoutes: React.FC = () => {
  const auth = useAuthContext();

  const isAuthenticated = auth.user != null;
  const authenticationPath = '/login';

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/401" element={<AccessDeniedPage />} />

      <Route
        path='/dlt-transactions'
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            authenticationPath={authenticationPath}
          >
            <DltTransactionsPage />
          </ProtectedRoute>
        }
      />

      <Route
        path='/'
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            authenticationPath={authenticationPath}
          >
            <AccountsPage />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
