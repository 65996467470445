import React, {Fragment} from 'react';
import {Button, Table} from 'react-bulma-components';
import './AccountsGrid.css';
import {PersonItem} from '../../api-models/person';
import {isEmptyString} from '../../utils/string.utils';
import ObjectRepresentation from '../common/ObjectRepresentation';

interface Props {
    readonly items: PersonItem[];
    readonly expandedItems: string[];
    readonly onExpandToggle: (id: string) => void;
}

const NO_DATA = '—';

const COLUMNS = [
  '',
  'Id',
  'First name',
  'Middle name',
  'Last name',
  'City',
  'Address',
  'Driver\'s license number',
  'Source'
];

const AccountsGrid: React.FC<Props> = ({items, expandedItems, onExpandToggle}) => {
  const renderRowCell = (value: string | null): React.ReactElement => (
    <td>{isEmptyString(value) ? NO_DATA : value}</td>
  );

  const renderRow = (item: PersonItem): React.ReactElement => {
    const hasAccounts = item.accounts.length > 0;
    const isExpanded = hasAccounts && expandedItems.includes(item.id);

    return (
      <Fragment key={item.id}>
        <tr>
          <td>
            {hasAccounts &&
              <Button
                onClick={() => onExpandToggle(item.id)}
                size='small'
                color='ghost'
              >
                <span className={`arrow ${isExpanded ? 'down' : 'right'}`} />
              </Button>
            }
          </td>

          <td>
            {item.id}
          </td>

          {renderRowCell(item.firstName)}
          {renderRowCell(item.middleName)}
          {renderRowCell(item.lastName)}
          {renderRowCell(item.city)}
          {renderRowCell(item.address)}
          {renderRowCell(item.documentIdNumber)}
          {renderRowCell(item.source)}
        </tr>

        {isExpanded &&
          <tr key={`${item.id}-details`}>
            <td className='details' colSpan={COLUMNS.length}>
              <div className='is-size-5 mb-4'>
                DLT accounts:
              </div>

              {item.accounts.map((x) => (
                <div
                  className={`mb-4 ${x.isDisabled ? 'disabled' : null}`}
                  key={x.address}
                >
                  <ObjectRepresentation value={x}/>
                </div>
              ))}
            </td>
          </tr>
        }
      </Fragment>
    );
  };

  return (
    <Table.Container>
      <Table>
        <thead>
          <tr>
            {COLUMNS.map((x, i) => <th key={i}>{x}</th>)}
          </tr>
        </thead>

        <tbody>
          {items.map(renderRow)}
        </tbody>
      </Table>
    </Table.Container>
  );
};

export default AccountsGrid;
