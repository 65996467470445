import React, {CSSProperties} from 'react';
import {HalfCircleSpinner} from 'react-epic-spinners';

interface Props {
  style?: CSSProperties;
}

const Loader: React.FC<Props> = ({style}) => {
  return <HalfCircleSpinner style={style} color='#00d1b2'/>;
};

export default React.memo(Loader);
