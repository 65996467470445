import {getOptionalEnvVariable, getRequiredEnvVariable} from './utils/env.utils';

export const env = {
  isProduction: getOptionalEnvVariable('NODE_ENV') === 'production',
  acs: {
    url: getRequiredEnvVariable('REACT_APP_ACS_URL'),
  },
  acsAuth: {
    url: getRequiredEnvVariable('REACT_APP_AUTH_URL'),
  }
};
