import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {parseJwt} from '../utils/jwt.utils';
import httpService from '../services/http/http.service';
import {env} from '../env';

interface CurrentUser {
  userId: string;
  username: string;
}

interface AuthContextType {
    user?: CurrentUser;
    login: (phoneNumber: string, code: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<CurrentUser>();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const setUserFromJwt = (token: string | null): void => {
    if (token == null) {
      return;
    }

    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 > Date.now()) {
      setUser({
        userId: decodedJwt.userId,
        username: decodedJwt.username
      });
    }
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwt');
    setUserFromJwt(jwtToken);

    setLoadingInitial(false);
  }, []);

  const logout = async (): Promise<void> => {
    localStorage.removeItem('jwt');

    setUser(undefined);
  };

  const login = async (username: string, password: string): Promise<void> => {
    const data = await httpService.post(`${env.acsAuth.url}/auth/token`, {
      username,
      password
    });

    if (data != null) {
      localStorage.setItem('jwt', data.accessToken);

      setUserFromJwt(data.accessToken);
    }
  };

  const memoedValue = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};
