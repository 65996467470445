import httpService from '../http/http.service';
import {
  SearchDltTransactionsResponse,
  SearchDltTransactionsParams,
  DltTransactionLogFullDetailsResponse
} from '../../api-models/dlt-transaction';
import {toCommonSearchParams} from '../../utils/search-requests.utils';

const dltTransactionLogService = {
  searchTransactions(page: number, pageSize: number, query?: string): Promise<SearchDltTransactionsResponse> {
    const params: SearchDltTransactionsParams = toCommonSearchParams(page, pageSize, query);

    return httpService.get<SearchDltTransactionsResponse>('/dlt-transactions-log', params)
      .catch((err) => {
        if (err?.status === 401) {
          return Promise.resolve({
            items: [],
            total: 0,
          });
        }
        throw err;
      });
  },
  getTransactionDetails(id: string): Promise<DltTransactionLogFullDetailsResponse> {
    return httpService.get<DltTransactionLogFullDetailsResponse>(`/dlt-transactions-log/${id}`);
  }
};

export default dltTransactionLogService;
