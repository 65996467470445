import React from 'react';
import 'bulma/css/bulma.min.css';
import {Block, Button, Container, Navbar} from 'react-bulma-components';
import AppRoutes from './AppRoutes';
import {useAuthContext} from './contexts/auth.context';
import {history} from './utils/history.utils';
import {NavLink} from 'react-router-dom';
import logo from './logo.png';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';

const App: React.FC = () => {
  const auth = useAuthContext();

  const logout = async (): Promise<void> => {
    await auth.logout();
    history.replace('/');
  };

  const renderNavigationLink = (text: string, path: string): React.ReactElement => (
    <Navbar.Item>
      <NavLink
        to={path}
        caseSensitive
        className={({isActive}) => isActive ? 'has-text-primary has-text-weight-bold' : 'has-text-black'}
      >
        {text}
      </NavLink>
    </Navbar.Item>
  );

  return (
    <HistoryRouter history={history}>
      <Container>
        {auth.user && (
          <header>
            <Navbar>
              <Navbar.Brand>
                <Navbar.Item
                  renderAs={NavLink}
                  to='/'
                >
                  <img
                    style={{maxHeight: '3rem'}}
                    src={logo}
                  />
                </Navbar.Item>
              </Navbar.Brand>

              <Navbar.Container>
                {renderNavigationLink('Customer accounts', '/')}
                {renderNavigationLink('DLT transactions', '/dlt-transactions')}
              </Navbar.Container>

              <Navbar.Container align="right">
                <Navbar.Item href="#">
                  <Button color='danger' onClick={logout}>
                    Logout
                  </Button>
                </Navbar.Item>
              </Navbar.Container>
            </Navbar>
          </header>
        )}

        <Block>
          <AppRoutes />
        </Block>
      </Container>
    </HistoryRouter>
  );
};

export default App;
