import axios, {AxiosResponse} from 'axios';
import {env} from '../../env';
import {history} from '../../utils/history.utils';
import {showErrorNotification} from '../../utils/notification.utils';

export interface HttpHeaders {
    [key: string]: string;
}

export type HttpResponse = AxiosResponse;

const prepareHeaders = (headers?: HttpHeaders): HttpHeaders => ({
  ...{
    'Content-Type': 'application/json',
  },
  ...(headers ?? {}),
});

axios.defaults.baseURL = env.acs.url;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt');
    if (token && config.headers != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === 'Network Error' && !error.response) {
    showErrorNotification('Cannot get a server response');
  }

  const {status, data, config, headers} = error.response;
  if (status === 404) {
    history.push('/404');
  }

  if (status === 401) {
    window.localStorage.removeItem('jwt');

    if (headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"') {
      history.push('/');
    } else {
      history.push('/401');
    }
  }

  if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
    history.push('/404');
  }

  if (status === 500) {
    showErrorNotification(`A server error occurred: ${error.message}`);
  }

  throw error.response;
});

const responseBody = (response: HttpResponse): any => response.data;

const httpService = {
  post(url: string, data: unknown, headers?: HttpHeaders): Promise<any> {
    return axios({
      method: 'POST',
      url,
      headers: prepareHeaders(headers),
      data: JSON.stringify(data),
    }).then(responseBody);
  },

  get<T>(url: string, params?: any, headers?: HttpHeaders): Promise<T> {
    return axios.get<T>(url, {params, headers: prepareHeaders(headers)}).then(responseBody);
  }
};

export default httpService;
