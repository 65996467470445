import React from 'react';
import {Navigate} from 'react-router-dom';

export interface Props {
    isAuthenticated: boolean;
    authenticationPath: string;
    children: React.ReactElement;
}

const ProtectedRoute: React.FC<Props> = ({
  isAuthenticated,
  authenticationPath,
  children
}: Props) => {
  if (!isAuthenticated) {
    return <Navigate to={{pathname: authenticationPath}} replace />;
  }

  return children;
};

export default ProtectedRoute;
