import React from 'react';
import {Block} from 'react-bulma-components';

interface Props {
    value: any;
}

const ObjectRepresentation: React.FC<Props> = ({value}) => {
  const renderValue = (propertyValue: any): React.ReactElement => {
    const isObject = typeof propertyValue === 'object';

    if (isObject) {
      return (
        <div className='ml-4'>
          <ObjectRepresentation value={propertyValue}/>
        </div>
      );
    }

    return (
      <span style={{wordBreak: 'break-all'}}>
        {propertyValue == null ? '-' : propertyValue.toString()}
      </span>
    );
  };

  return (
    <Block>
      {Object.keys(value).map((key) => {
        return (
          <div className='mb-1' key={key}>
            <span className='has-text-weight-bold is-capitalized'>
              {key}: {' '}
            </span>

            {renderValue(value[key])}
          </div>
        );
      })}
    </Block>
  );
};

export default ObjectRepresentation;
