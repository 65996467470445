import React from 'react';
import {Panel} from 'react-bulma-components';
import {DltTransactionOutcome, DltTransactionsLogItem} from '../../api-models/dlt-transaction';
import ObjectRepresentation from '../common/ObjectRepresentation';

interface Props {
    transaction: DltTransactionsLogItem;
    outcome?: DltTransactionOutcome;
}

const DltTransactionDetails: React.FC<Props> = ({transaction, outcome}) => {
  const renderObject = (title: string, value: any): React.ReactElement => (
    <Panel>
      <Panel.Header>
        {title}
      </Panel.Header>

      <Panel.Block>
        <ObjectRepresentation value={value} />
      </Panel.Block>
    </Panel>
  );

  return (
    <>
      {renderObject('Details', transaction)}

      {outcome && renderObject('DLT outcome', outcome)}
    </>
  );
};

export default DltTransactionDetails;
