import React from 'react';
import {Pagination} from 'react-bulma-components';
import {GRID_PAGE_SIZE} from '../../constants/grid.constants';

interface Props {
    total: number;
    currentPage: number;
    onChange: (page: number) => void;
}

const GridPagination: React.FC<Props> = ({total, currentPage, onChange}) => (
  <Pagination
    current={currentPage}
    showFirstLast
    total={Math.ceil(total / GRID_PAGE_SIZE)}
    onChange={onChange}
  />
);

export default GridPagination;
