import React, {useEffect, useState} from 'react';
import {SearchDltTransactionsLogItem} from '../api-models/dlt-transaction';
import {GRID_PAGE_SIZE} from '../constants/grid.constants';
import dltTransactionLogService from '../services/dlt-transaction/dlt-transaction-log.service';
import {Block, Box} from 'react-bulma-components';
import GridSearchPanel from '../components/common/GridSearchPanel';
import GridPagination from '../components/common/GridPagination';
import GridLoadingIndicator from '../components/common/GridLoadingIndicator';
import DltTransactionsGrid from '../components/dlt-transactions/DltTransactionsGrid';
import DltTransactionDetailsModal from '../components/dlt-transactions/DltTransactionDetailsModal';

const DEFAULT_PAGE = 1;

const DltTransactionsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState<string>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [items, setItems] = useState<SearchDltTransactionsLogItem[]>([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const {items, total} = await dltTransactionLogService.searchTransactions(page, GRID_PAGE_SIZE, query ?? '');

      setTotal(total);
      setItems(items);
      setIsLoading(false);
    })();
  }, [page, query]);

  const onSearch = (q?: string): void => {
    setQuery(q);
    setPage(DEFAULT_PAGE);
  };

  return (
    <>
      <Box>
        <Box>
          <GridSearchPanel
            placeholder='Search by transactionId, account, owner, destination, authorize'
            onSearch={onSearch}
          />
        </Box>

        {isLoading && <GridLoadingIndicator/>}

        {!isLoading &&
          <>
            <Block>
              <span className='has-text-weight-bold'>
                Total transactions:
              </span>
              &nbsp;{total}
            </Block>

            <DltTransactionsGrid
              items={items}
              onItemClicked={setSelectedTransactionId}
            />

            <GridPagination
              total={total}
              currentPage={page}
              onChange={setPage}
            />
          </>
        }
      </Box>

      {selectedTransactionId &&
        <DltTransactionDetailsModal
          transactionId={selectedTransactionId}
          onClose={() => setSelectedTransactionId(null)}
        />
      }
    </>

  );
};

export default DltTransactionsPage;
