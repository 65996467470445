import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from '../contexts/auth.context';
import {Button, Container, Form, Heading, Hero, Notification} from 'react-bulma-components';
import {isEmptyString} from '../utils/string.utils';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const navigate = useNavigate();
  const auth = useAuthContext();

  const login = async (): Promise<void> => {
    setError(undefined);

    try {
      await auth.login(username, password);
      navigate('/');
    } catch (error: any) {
      setError(error?.data?.msg ?? 'An error occurred');
    }
  };

  const canLogin = !isEmptyString(username) && !isEmptyString(password);

  return (
    <Hero>
      <Hero.Body>
        <Container>
          <Heading>
            Login
          </Heading>

          {error &&
            <Notification color='danger'>
              {error}
            </Notification>
          }

          <Form.Field>
            <Form.Label>Username</Form.Label>
            <Form.Control>
              <Form.Input
                value={username}
                onChange={({target: {value}}) => setUsername(value)}
              />
            </Form.Control>
          </Form.Field>

          <Form.Field>
            <Form.Label>Password</Form.Label>
            <Form.Control>
              <Form.Input
                type='password'
                value={password}
                onChange={({target: {value}}) => setPassword(value)}
              />
            </Form.Control>
          </Form.Field>

          <Form.Field>
            <Form.Control>
              <Button
                color="link"
                disabled={!canLogin}
                onClick={login}
              >
                Login
              </Button>
            </Form.Control>
          </Form.Field>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default LoginPage;
