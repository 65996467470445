import React, {useEffect, useState} from 'react';
import personService from '../services/person/person.service';
import AccountsGrid from '../components/accounts/AccountsGrid';
import {Block, Box} from 'react-bulma-components';
import GridSearchPanel from '../components/common/GridSearchPanel';
import {GRID_PAGE_SIZE} from '../constants/grid.constants';
import GridPagination from '../components/common/GridPagination';
import GridLoadingIndicator from '../components/common/GridLoadingIndicator';
import {PersonItem} from '../api-models/person';

const DEFAULT_PAGE = 1;

const AccountsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState<string>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [items, setItems] = useState<PersonItem[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const {items, total} = await personService.searchPersons(page, GRID_PAGE_SIZE, query ?? '');

      setTotal(total);
      setItems(items);
      setIsLoading(false);
      setExpandedItems([]);
    })();
  }, [page, query]);

  const onSearch = (q?: string): void => {
    setQuery(q);
    setPage(DEFAULT_PAGE);
  };

  const onExpandToggle = (id: string) => {
    setExpandedItems((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((x) => x != id);
      }

      return [
        ...prevState,
        id,
      ];
    });
  };

  return (
    <Box>
      <Box>
        <GridSearchPanel
          placeholder='Search by any field'
          onSearch={onSearch}
        />
      </Box>

      {isLoading && <GridLoadingIndicator/>}

      {!isLoading &&
        <>
          <Block>
            <span className='has-text-weight-bold'>
              Total:
            </span>
                &nbsp;{total}
          </Block>

          <AccountsGrid
            items={items}
            expandedItems={expandedItems}
            onExpandToggle={onExpandToggle}
          />

          <GridPagination
            total={total}
            currentPage={page}
            onChange={setPage}
          />
        </>
      }
    </Box>
  );
};

export default AccountsPage;
